import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    AppBar,
    Box,
    Button,
    Divider,
    Fab,
    IconButton,
    Menu,
    MenuItem, Stack,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import {AccountCircle, Menu as MenuIcon} from "@mui/icons-material";
import useApiCall from "../helpers/useApiCall";


const Layout = ({ children }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        if(typeof laneId === "number")
            laneId && navigate(`/lanes/${laneId}`)
        setAnchorElNav(null);
    };

    const navigateTo = (location) => {
        navigate(location);
        handleCloseNavMenu()
    }


    const { loading, failed, result: lanes, mutate: refetch } = useApiCall({
        url: "/api/lanes"
    })

    return <div className="Layout">
        <AppBar position="fixed" color="primary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, top: 0 }}>
            <Toolbar>
                {user && user.approved &&
                    <Stack spacing={2} direction="row" sx={{flexGrow: 1}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            <MenuItem onClick={() => navigateTo("/switches/changed") }>
                                <Typography textAlign="center">Eltérések</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo("/logs")}>
                                <Typography textAlign="center">Napló</Typography>
                            </MenuItem>
                            <Divider />
                            {(lanes || []).map((lane) => (
                                <MenuItem key={lane.id} onClick={() => navigateTo(`/lanes/${lane.id}`)}>
                                    <Typography textAlign="center">{lane.name}</Typography>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem onClick={() => navigateTo("/Lanes") }>
                                <Typography textAlign="center">Minden vonal</Typography>
                            </MenuItem>
                            {
                                user.admin && <MenuItem onClick={() => { navigateTo("/users") }}>
                                    <Typography textAlign="center">Felhasználók</Typography>
                                </MenuItem>
                            }
                        </Menu>
                        {matches && <Stack spacing={4} direction="row" overflow="break">
                            {
                                user.approved && <>
                                    <Button color="inherit" onClick={() => navigateTo("/switches/changed")}>
                                        Eltérések
                                    </Button>
                                    <Button color="inherit" onClick={() => navigateTo("/logs")}>
                                        Napló
                                    </Button>
                                </>
                            }
                            {(lanes || []).map((lane) => (
                                <Button key={lane.id} color="inherit" onClick={() => navigateTo(`/lanes/${lane.id}`)}>
                                    {lane.name}
                                </Button>
                            ))}
                        </Stack>
                        }
                    </Stack>
                }
                <Box sx={{ flexGrow: 1 }} />
                {
                    user && <>
                        <IconButton color="inherit" onClick={handleOpenUserMenu}>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem>
                                <Typography textAlign="center">{user.username}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => { window.location.replace("/logout") }}>
                                <Typography textAlign="center">Kijelentkezés</Typography>
                            </MenuItem>
                        </Menu>
                    </>
                }
            </Toolbar>
        </AppBar>
        <div style={{height: "70px"}}></div>
        <div className="main">
            {children}
        </div>
    </div>
}

export default Layout;
