import React, {useEffect, useReducer, useState} from 'react';
import {Alert, Container, Tabs, Tab, Box, Button} from "@mui/material";
import {useParams} from "react-router-dom";
import useApiCall from "../helpers/useApiCall";
import AddNewBottomButton from "../components/addNewBottomButton";
import AddLocation from "../components/addLocation";
import Location from "./location";

const TabPanel = ({ value, index, children }) => {
    if(value !== index) return null;
    return <Box>
        {children}
    </Box>
}

const Lane = () => {
    const { laneId } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const [addLocationOpen, toggleAddLocationOpen] = useReducer((v) => !v, false, _ => false)
    const { loading, result: lane, failed, mutate: refetch } = useApiCall({
        url: `/api/lanes/${laneId}`
    });

    useEffect(() => {
        setSelectedTab(0);
    }, [laneId])

    if (loading) {
        return <Alert severity="info">Adatok betöltése folyamatban.....</Alert>
    }

    if (failed) {
        return <Alert severity="error">Nem sikerült betölteni az útvonalat!</Alert>
    }

    const locations = lane && lane.locations || [];

    return <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "5px" }}>
            <Tabs variant="scrollable" value={selectedTab} onChange={(event, newValue) => {
                setSelectedTab(newValue);
            }}>
                {
                    locations.map(l => <Tab label={l.name} />)
                }
            </Tabs>

        </Box>
        {
            locations.map((l, i) => <TabPanel value={selectedTab} index={i}><Location locationId={l.id} /></TabPanel>)
        }
        {
            user.admin && <>
                <AddNewBottomButton onClick={toggleAddLocationOpen} />
                <AddLocation open={addLocationOpen} onClose={() => { refetch(); toggleAddLocationOpen() }}  />
            </>
        }
    </Box>
}

export default Lane;

