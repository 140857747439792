import {Navigate} from "react-router-dom";
import {Alert, Box, Card, CardContent, CardMedia, Container, Typography, useMediaQuery} from "@mui/material";
import React from "react";

const ApprovalNeeded = () => {
    const matches = useMediaQuery(`(max-width:600px)`)

    if(user && user.approved) {
        return <Navigate to={"/lanes"} />
    }

    return <Container maxWidth="sm">
        <Alert severity="info">A regisztrációd még nincs megerősítve. Kérj megerősítést!</Alert>
        <Card sx={{ display: 'flex', margin: '15px', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        Komáromi József
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        +36/20/459-9490
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        komaromi.jozsef@mav-hev.hu
                    </Typography>
                </CardContent>
            </Box>
            {!matches && <CardMedia
                component="img"
                sx={{width: 151}}
                image="/kojo.jpg"
                alt="Live from space album cover"
            />}
        </Card>
    </Container>
}

export default ApprovalNeeded;

