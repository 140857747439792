import React,{useState} from "react";
import {
    Alert, Box,
    Button, Checkbox,
    DialogTitle, FormControlLabel, Modal,
    TextField
} from "@mui/material";
import useApiCall from "../helpers/useApiCall";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column'
};

const AddSwitch = (props) => {
    const { onClose, open, locationId } = props;
    const [name, setName] = useState("");
    const [defaultState, setDefaultState] = useState(false);
    const [currentState, setCurrentState] = useState(false);
    const {loading, failed, mutate: addSwitch} = useApiCall(
        {
            url: `/api/locations/${locationId}/switches`,
            method: "POST",
            body: { name, state: currentState, original_state: defaultState },
            onSuccess: onClose
        }
    );

    return (
        <Modal open={open}>
            <Box sx={{ ...style }}>
                <DialogTitle>Új szakaszoló</DialogTitle>
                {failed && <Alert severity="error">Nem sikerült!</Alert>}
                <TextField value={name} onChange={e => setName(e.target.value)} label="Szakaszoló neve" />
                <FormControlLabel control={<Checkbox checked={defaultState} onChange={e => setDefaultState(e.target.checked)} />} label="Alap állapot" />
                <FormControlLabel control={<Checkbox checked={currentState} onChange={e => setCurrentState(e.target.checked)} />} label="Jelenlegi állapot" />
                <Box display="flex" flexDirection="column" padding="10px">
                    <Button disabled={loading} onClick={() => addSwitch()} variant="contained">Szakaszoló hozzáadása</Button>
                    <Button disabled={loading} onClick={onClose}>Bezár</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddSwitch;
