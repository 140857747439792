import React,{useState} from "react";
import {
    Alert, Box,
    Button,
    DialogTitle, Modal,
    TextField
} from "@mui/material";
import useApiCall from "../helpers/useApiCall";
import {useParams} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column'
};

const AddLane = (props) => {
    const { onClose, open } = props;
    const [name, setName] = useState("");
    const {loading, failed, mutate: addLane} = useApiCall(
        {
            url: "/api/lanes",
            method: "POST",
            body: { name },
            onSuccess: onClose
        }
    )

    return (
        <Modal open={open}>
            <Box sx={{ ...style }}>
                <DialogTitle>Új HÉV vonal</DialogTitle>
                {failed && <Alert severity="error">Nem sikerült!</Alert>}
                <TextField value={name} onChange={e => setName(e.target.value)} label="Vonal neve" />
                <Box display="flex" flexDirection="column" padding="10px">
                    <Button disabled={loading} onClick={() => addLane()} variant="contained">Vonal hozzáadása</Button>
                    <Button disabled={loading} onClick={onClose}>Bezár</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddLane;
