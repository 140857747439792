import styled from "@emotion/styled";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

const StyledFab = styled(Fab)({
    position: 'fixed',
    zIndex: 10,
    bottom: '40px',
    right: '30px',
    margin: '0 auto',
});

const AddNewBottomButton = (props) => {
    return <StyledFab color="secondary" aria-label="add" { ...props }>
        <AddIcon />
    </StyledFab>
};

export default AddNewBottomButton;
