import React from "react";
import {Checkbox, Typography} from "@mui/material";
import {Circle} from "@mui/icons-material";

const SwitchState = ({checked, onClick, disabled}) => (
    <>
        <Checkbox
            checked={checked}
            tabIndex={-1}
            disableRipple
            onClick={onClick}
            checkedIcon={<Circle style={{color: disabled ? 'lightsalmon' : 'crimson'}} />}
            icon={<Circle style={{color: disabled ? 'lightgreen' : 'green'}} />}
            disabled={disabled}
        />
        <Typography>
            {checked ? "Bent" : "Kint"}
        </Typography>
    </>
);

export default SwitchState;
