import React from 'react';
import {Alert, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import useApiCall from "../helpers/useApiCall";
import {useParams} from "react-router-dom";
import SwitchState from "../components/switchState";

const Logs = () => {
    const { switchId } = useParams();
    const { result: logs = [], loading, failed } = useApiCall({
        url: switchId ?  `/api/logs/${switchId}` : '/api/logs',
    })

    if (loading) {
        return <Alert severity="info">Adatok betöltése folyamatban.....</Alert>
    }

    if (failed) {
        return <Alert severity="error">Nem sikerült betölteni az adatokat!</Alert>
    }

    return <Container maxWidth="lg">
        <Typography variant="h6">Esemény Lista(Napló)</Typography>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">Vonal</TableCell>
                    <TableCell align="center">Szakaszoló</TableCell>
                    <TableCell align="center">Alap Helyzete</TableCell>
                    <TableCell align="center">Jelenlegi Állapota</TableCell>
                    <TableCell align="center">Változás Dátuma</TableCell>
                    <TableCell align="center">Naplózó / Végrehajtó</TableCell>
                    <TableCell align="center">Megjegyzés</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    logs.map(l => <TableRow key={l.id}>
                        <TableCell align="center">{l.lane}</TableCell>
                        <TableCell align="center">{l.switch.name}</TableCell>
                        <TableCell align="center"><SwitchState checked={l.new_original_state} disabled={true} /></TableCell>
                        <TableCell align="center"><SwitchState checked={l.new_state} disabled={true} /></TableCell>
                        <TableCell align="center">{new Date(l.created_at).toLocaleString()}</TableCell>
                        <TableCell align="center">{l.performing_user.username}</TableCell>
                        <TableCell align="center">{l.note}</TableCell>
                    </TableRow>)
                }
            </TableBody>
        </Table>
    </Container>
}

export default Logs;
