import React from 'react'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/login";
import Layout from "./components/layout";
import Register from "./pages/register";
import Default from "./pages/default";
import Lanes from "./pages/lanes";
import ApprovalNeeded from "./pages/approvalNeeded";
import Users from "./pages/users";
import Lane from "./pages/lane";
import ChangedSwitches from "./pages/changedSwitches";
import Logs from "./pages/logs";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import ErrorBoundary from "./helpers/errorBoundary";

export const themeOptions = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#0b8337',
        },
        secondary: {
            main: '#388e3c',
        },
    },
});


const ClientApp = () => {
    return <ErrorBoundary>
        <BrowserRouter>
            <ThemeProvider theme={themeOptions}>
                <Layout>
                    <Routes>
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/register" element={<Register />} />
                        {user && user.approved && <Route exact path="/lanes" element={<Lanes/>}/>}
                        {user && user.approved && <Route exact path="/lanes/:laneId" element={<Lane />} />}
                        {user && user.approved && <Route exact path="/switches/changed" element={<ChangedSwitches />} />}
                        {user && user.approved && <Route path="/logs/" element={<Logs />} /> }
                        {user && user.approved && <Route path="/logs/:switchId" element={<Logs />} />}
                        {user && user.admin && <Route exact path="/users" element={<Users />} />}
                        {user && <Route exact path="/approval-needed" element={<ApprovalNeeded />} />}
                        <Route path="*" element={<Default />} />
                    </Routes>
                </Layout>
            </ThemeProvider>
        </BrowserRouter>
    </ErrorBoundary>
}

export default ClientApp;
