import React, {useCallback, useEffect, useReducer, useState} from 'react';

const useApiCall = ({url, method, body, onSuccess, onFailed, contentType}) => {
    const [loading, toggleLoading] = useReducer((v) => !v, false, _ => false);
    const [failed, setFailed] = useState();
    const [result, setResult] = useState();

    const mutate = useCallback(async (paramBody) => {
        toggleLoading();
        const finalBody = paramBody || body
        const type = contentType || "application/json";

        const result = await fetch(url,
        {
                method,
                body: type === "application/json" ? finalBody && JSON.stringify(finalBody) : finalBody,
                headers: {
                    "Content-Type": type
                }
            });

        if(result.ok) {
            let data;
            try {
                data = await result.json();
                setResult(data);
            } catch {}
            onSuccess && onSuccess(data);
        } else {
            setFailed(true);
            onFailed && onFailed();
        }
        toggleLoading();
    }, [method, url, body, onSuccess, onFailed])

    useEffect(() => {
        if(!method || method.toLowerCase() === 'get') {
            mutate();
        }
    }, [method, url])

    return { loading, failed, result, mutate }
}

export default useApiCall;
