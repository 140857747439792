import React, {useCallback, useReducer, useState} from "react";
import {
    Alert,
    Button, Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import useApiCall from "../helpers/useApiCall";
import AddNewBottomButton from "../components/addNewBottomButton";
import {Link} from "react-router-dom";
import AddLane from "../components/addLane";

const Lanes = () => {
    const [addLaneOpen, toggleAddLaneOpen] = useReducer((v) => !v, false, _ => false)
    const { loading, failed, result: lanes, mutate: refetch } = useApiCall({
        url: "/api/lanes"
    })
    const { loading: deleteLoading, mutate: deleteLane } = useApiCall({
        url: "/api/lanes",
        method: "DELETE",
        onSuccess: () => window.location.reload()
    })

    const confirmDelete = (id) => {
        if(confirm("Biztosan törlöd?")) {
            deleteLane({lane_id: id})
        }
    }

    return <Container maxWidth="sm">
        <Typography variant="h6">HÉV - Vonalak</Typography>
        <br/>
        {failed && <Alert severity="error">Nem sikerült betölteni a HÉV vonalakat!</Alert>}
        {loading && <Alert severity="info">Adatok betöltése folyamatban.....</Alert>}
        <Stack spacing={2}>
            {
                lanes && lanes.map(l =>
                    <Card key={l.id}>
                        <CardContent>
                            <Typography variant="h5">{l.name}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" component={Link} to={`/lanes/${l.id}`}>MEGNÉZ</Button>
                            {user.admin && <Button color="error" onClick={() => confirmDelete(l.id)} disabled={deleteLoading}>Törlés</Button>}
                        </CardActions>
                    </Card>
                )
            }
        </Stack>
        {
            user.admin && <>
                <AddNewBottomButton onClick={toggleAddLaneOpen} />
                <AddLane open={addLaneOpen} onClose={() => {refetch(); toggleAddLaneOpen()}} />
            </>
        }
    </Container>
}

export default Lanes;

