import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Alert, Button, Container, Stack, TextField} from "@mui/material";
import useApiCall from "../helpers/useApiCall";

const Login = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { state } = useLocation();
    const { failed, loading, mutate: login } = useApiCall({
        url: "/api/login",
        method: "POST",
        body: {username: userName.trim(), password},
        onSuccess: () => window.location.href = "/locations"
    });

    useEffect(() => {
        const handler = (k) => {
            if(k.code === 'Enter')
                document.getElementById('login').click()
        }
        window.addEventListener('keypress', handler);
        return () => window.removeEventListener('keypress', handler);
    }, [])

    return <Container maxWidth="sm">
        <Stack spacing={2}>
            {state && state.success && <Alert severity="success">Sikeres regisztráció! A regisztráció elfogadása után a folytatáshoz jelentkezz be!</Alert>}
            {failed && <Alert severity="error">Nem sikerült bejelentkezni! Érvénytelen felhasználó név vagy jelszó!</Alert>}
            <TextField autoFocus label="Nev" value={userName} onChange={(e) => setUserName(e.target.value)}/>
            <TextField label="Jelszo" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            <Button id="login" variant="contained" disabled={loading} onClick={() => login()}>BEJELENTKEZÉS</Button>
            <Button disabled={loading} onClick={() => navigate('/register')}>REGISZTRÁCIÓ</Button>
        </Stack>
    </Container>
}

export default Login;
