import React, {useEffect} from 'react'
import {Link, useNavigate} from "react-router-dom";
import {Button, Container, Stack} from "@mui/material";

const Default = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) return;
        if (!user.approved) return navigate("/approval-needed");
        navigate("/lanes")
    }, [])

    return <Container maxWidth="sm">
        <Stack spacing={2}>
            <Button variant="contained" component={Link} to="/login">Bejelentkezés</Button>
            <Button variant="contained" component={Link} to="/register">Regisztráció</Button>
        </Stack>
    </Container>
}

export default Default;
