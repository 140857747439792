import React, {useReducer, useState} from 'react';
import {
    Alert,
    Box,
    Input,
    Button,
    Stack,
    Checkbox,
    Typography,
    TableCell, TableBody, TableRow, Table, TableHead, useMediaQuery, Card, Container
} from "@mui/material";
import {makeStyles} from "@mui/styles"
import useApiCall from "../helpers/useApiCall";
import {DeleteForever, RailwayAlert} from "@mui/icons-material";
import AddSwitch from "../components/addSwitch";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SwitchState from "../components/switchState";


const sideBarWidth = "515px";
const mobileBreakPoint = '900px'

const useStyles = makeStyles(({
    sideBar: {
        maxWidth: sideBarWidth,
        maxHeight: "94vh",
        overflowY: "auto",
        overflowX: "hidden",
        paddingRight: "25px",
        [`@media (max-width: ${mobileBreakPoint})`]: {
            maxWidth: '100%',
            maxHeight: 'unset',
            overflowX: 'scroll'
        }
    },
    content: {
        maxWidth: `calc(97vw - ${sideBarWidth})`,
        overflow: "scroll",
        [`@media (max-width: ${mobileBreakPoint})`]: {
            maxWidth: '100%',
        }
    }
}))

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgray" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const SvgImage = ({ svg }) => {
    let blob = new Blob([svg], {type: 'image/svg+xml'});
    let url = URL.createObjectURL(blob);

    return <>
        <Button variant="contained" fullWidth onClick={() => window.open(url)}>A Rajz megnyítása teljes képernyőn</Button>
        <img src={url} style={{ maxHeight: '90vh' }} />
    </>
}

const Location = ({ locationId }) => {
    const matches = useMediaQuery(`(min-width:${mobileBreakPoint})`)
    const { sideBar, content } = useStyles();
    const [file, setFile] = useState();
    const [switches, setSwitches] = useState([]);
    const [addSwitchOpen, toggleAddSwitchOpen] = useReducer((v) => !v, false, _ => false)
    const { loading, failed, result: location, mutate: refetch } = useApiCall({
        url: `/api/locations/${locationId}`,
        onSuccess: (result) => setSwitches(result.switches)
    });
    const { loading: uploadLoading, failed: uploadFailed, mutate: upload } = useApiCall({
        url: `/api/locations/${locationId}/upload`,
        method: "POST",
        contentType: "multipart/form-data",
        body: file,
        onSuccess: () => refetch()
    });
    const { mutate: callUpdateLocationName } = useApiCall({
        url: `/api/locations/${locationId}`,
        method: "PATCH",
        onSuccess: () => window.location.reload()
    });
    const { loading: updateSwitchLoading, failed: uploadSwitchFailed, mutate: callUpdateSwitch } = useApiCall({
       url: `/api/switches`,
        method: "PATCH",
        onSuccess: () => refetch()
    });
    const { loading: deleteSwitchLoading, failed: deleteSwitchFailed, mutate: callDeleteSwitch } = useApiCall({
        url: `/api/switches`,
        method: "DELETE",
        onSuccess: () => refetch()
    });
    const { loading: orderUpdateLoading, failed: orderUpdateFailed, mutate: updateOrder } = useApiCall({
        url: '/api/switches/update_order',
        method: 'PATCH',
    });


    const updateSwitch = (e, params) => {
        const note = prompt("Miért történt a változtatás?  Ki végezte/jelentette a szakaszolást?")
        if(note === "" || note === null) e.preventDefault();
        else callUpdateSwitch({...params, note})
    }

    const updateSwitchName = (e, params) => {
        const name = prompt("Mi legyen az új neve?", params.name)
        if(name === "" || name === null) e.preventDefault();
        else callUpdateSwitch({...params, name})
    }

    const updateLocationName = () => {
        const name = prompt("Mi legyen az új neve?", location.name)
        if(name === "" || name === null) return;
        else callUpdateLocationName({name})
    }

    if (failed) {
        return <Alert severity="error">Nem sikerült betölteni a helyszint!</Alert>
    }

    const setUploadFile = (e) => {
        setFile(e.target.files[0]);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const orderedItems = reorder(
            switches,
            result.source.index,
            result.destination.index
        );

       setSwitches(orderedItems);
       updateOrder({switches: orderedItems.map((s, i) => ({ ...s, order: i }))});
    }
    return <Stack direction={matches ? 'row' : 'column'} spacing={2}>
        <Box className={sideBar}>
            {
                user.admin && <>
                    <Button variant="contained" onClick={() => toggleAddSwitchOpen()} fullWidth sx={{ margin: '5 0' }}>
                        <Typography>Új Szakaszoló</Typography>
                    </Button>
                    <AddSwitch onClose={() => {toggleAddSwitchOpen(); refetch() }} open={addSwitchOpen} locationId={locationId} />
                </>
            }
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Szakaszoló neve</TableCell>
                        <TableCell align="center">Alap helyzete</TableCell>
                        <TableCell align="center">Jelenlegi állapota</TableCell>
                        <TableCell align="center">Eltérés</TableCell>
                        { user.admin && <TableCell align="center">Törlés</TableCell>}
                    </TableRow>
                </TableHead>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <TableBody {...provided.droppableProps}
                                           ref={provided.innerRef}
                                           style={getListStyle(snapshot.isDraggingOver)}>
                                    {
                                        switches.map((s, index) => (<Draggable key={s.id} draggableId={"" + s.id} index={index} isDragDisabled={!user.admin}>
                                                {(provided, snapshot) => (
                                                    <TableRow ref={provided.innerRef}
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              style={getItemStyle(
                                                                  snapshot.isDragging,
                                                                  provided.draggableProps.style
                                                              )}>
                                                            <TableCell
                                                                onDoubleClick={(e) => updateSwitchName(e, {switch_id: s.id, ...s})}><Typography
                                                                textAlign="center">{s.name}</Typography></TableCell>
                                                            <TableCell align="center">
                                                                <SwitchState
                                                                    checked={s.original_state}
                                                                    onClick={(e) => updateSwitch(e, {
                                                                        switch_id: s.id,
                                                                        original_state: !s.original_state,
                                                                        name: s.name,
                                                                        state: s.state
                                                                    })}
                                                                    disabled={!user.admin || user.read_only || updateSwitchLoading || loading || deleteSwitchLoading}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <SwitchState
                                                                    checked={s.state}
                                                                    onClick={(e) => updateSwitch(e, {
                                                                        switch_id: s.id,
                                                                        original_state: s.original_state,
                                                                        name: s.name,
                                                                        state: !s.state
                                                                    })}
                                                                    disabled={user.read_only || updateSwitchLoading || loading || deleteSwitchLoading}
                                                                    />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {s.state !== s.original_state && <RailwayAlert/>}
                                                            </TableCell>
                                                            {
                                                                user.admin && <TableCell align="center"
                                                                                         onClick={() => confirm("Biztosan torlod?") && callDeleteSwitch({switch_id: s.id})}><DeleteForever/></TableCell>
                                                            }
                                                    </TableRow>)}
                                            </Draggable>))
                                    }
                                    {provided.placeholder}
                                </TableBody>)
                            }</Droppable>
                    </DragDropContext>
            </Table>
        </Box>
        <Box className={content} sx={matches && { maxWidth: '100%' }}>
            {loading && <Alert severity="info">Adatok betöltése folyamatban.....</Alert>}
            <Box width="100%">
                {
                    user.admin &&
                        <Card sx={{ padding: '15px' }}>
                            <Stack direction="column" spacing={2} maxWidth="sm">
                                <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={setUploadFile} />
                                </label>
                                <br/>
                                <Button variant="contained" component="span" disabled={!file} onClick={() => upload()}>
                                    Feltöltés
                                </Button>
                                <Button variant="outlined" onClick={updateLocationName}>
                                    Szakasz átnevezése
                                </Button>
                            </Stack>
                        </Card>
                }
                { location && location.image && <SvgImage svg={location.image.svg} />}
            </Box>
        </Box>
    </Stack>
}

export default Location;

