import React from "react";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent, Checkbox,
    Container,
    FormControlLabel,
    Stack,
    Typography
} from "@mui/material";
import useApiCall from "../helpers/useApiCall";

const Users = () => {
    const {fetchLoading, fetchFailed, result: users} = useApiCall({
        url: "/api/users"
    })
    const {deleteLoading, mutate: deleteUser} = useApiCall({
        url: `/api/users`,
        method: "DELETE",
        onSuccess: () => window.location.reload()
    })
    const {approveLoading, mutate: approveUser} = useApiCall({
        url: `/api/users/approve`,
        method: "POST",
        onSuccess: () => window.location.reload()
    })
    const {removeAdminLoading, mutate: removeAdmin} = useApiCall({
        url: `/api/users/remove_admin`,
        method: "DELETE",
        onSuccess: () => window.location.reload()
    })
    const {makeAdminLoading, mutate: makeAdmin} = useApiCall({
        url: `/api/users/make_admin`,
        method: "PATCH",
        onSuccess: () => window.location.reload()
    })
    const {makeReadOnlyLoading, mutate: makeReadOnly} = useApiCall({
        url: `/api/users/make_readonly`,
        method: "PATCH",
        onSuccess: () => window.location.reload()
    })
    const {removeReadOnlyLoading, mutate: removeReadOnly} = useApiCall({
        url: `/api/users/remove_readonly`,
        method: "DELETE",
        onSuccess: () => window.location.reload()
    })

    if (fetchLoading) {
        return <Alert severity="info">Adatok betöltése folyamatban.....</Alert>
    }

    if (fetchFailed ) {
        return <Alert severity="error">Nem sikerült letölteni a felhasználói listát</Alert>
    }

    const loading = removeAdminLoading || makeAdminLoading || makeReadOnlyLoading || removeReadOnlyLoading;

    return <Container maxWidth="sm">
        <Typography variant="h6">Felhasználók</Typography>
        <br/>
        <Stack spacing={2}>
            {
                users && users.map(u => <Card key={u.id}>
                    <CardContent>
                        <Typography>{u.username}</Typography>
                        {u.approved && <Typography variant="secondary">Megerősitett felhasználó</Typography>}
                    </CardContent>
                    <CardActions>
                        <Stack spacing={2}>
                            {!u.approved && <Button variant="contained" disabled={approveLoading} onClick={() => approveUser({user_id: u.id})}>Megerősit</Button>}
                            <Button variant="contained" color="error" disabled={deleteLoading} onClick={() => deleteUser({user_id: u.id})}>Töröl</Button>
                            <FormControlLabel
                                disabled={loading}
                                control={
                                    <Checkbox
                                        checked={u.read_only}
                                        onChange={e => e.target.checked ? makeReadOnly({user_id: u.id}) : removeReadOnly({user_id: u.id})}
                                    />
                                }
                                label="Csak olvasó" />
                            <FormControlLabel
                                disabled={loading}
                                control={
                                    <Checkbox
                                        checked={u.admin}
                                        onChange={e => e.target.checked ? makeAdmin({user_id: u.id}) : removeAdmin({user_id: u.id})}
                                    />
                                }
                                label="Adminisztrátor" />
                        </Stack>
                    </CardActions>
                </Card>)
            }
        </Stack>
    </Container>
}

export default Users
