import React from 'react';
import {Alert, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import useApiCall from "../helpers/useApiCall";
import {Comment, RailwayAlert} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import SwitchState from "../components/switchState";

const ChangedSwitches = () => {
    const navigate = useNavigate();
    const { loading, failed, result: switches = [], mutate: refetch } = useApiCall({
        url: '/api/switches/changed'
    })

    return <Container maxWidth="lg">
        <Typography variant="h6">Nem alaphelyzetben levő szakaszolók(Eltérések)</Typography>
        {loading && <Alert severity="info">Adatok betöltése folyamatban.....</Alert>}
        {failed && <Alert severity="error">Hiba történt</Alert>}
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Vonal</TableCell>
                    <TableCell>Szakaszoló neve</TableCell>
                    <TableCell align="center">Alap helyzete</TableCell>
                    <TableCell align="center">Jelenlegi állapota</TableCell>
                    <TableCell align="center">Utoljára módosítva</TableCell>
                    <TableCell align="center">Megjegyzések</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    switches.map(s => <TableRow>
                        <TableCell><Typography>{s.lane}</Typography></TableCell>
                        <TableCell><Typography>{s.name}</Typography></TableCell>
                        <TableCell align="center">
                            <SwitchState
                                checked={s.original_state}
                                onClick={() => {}}
                                disabled={true} />
                        </TableCell>
                        <TableCell align="center">
                            <SwitchState
                                checked={s.state}
                                onClick={() => {}}
                                disabled={true} />
                        </TableCell>
                        <TableCell align="center">
                            <Typography>
                                {new Date(s.updated_at).toLocaleString()}
                            </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ cursor: "pointer" }}  onClick={() => navigate(`/logs/${s.id}`)}><Comment /></TableCell>
                    </TableRow>)
                }
            </TableBody>
        </Table>
    </Container>
}

export default ChangedSwitches;
