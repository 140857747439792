import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Alert, Button, Container, FormLabel, Input, Stack, TextField} from "@mui/material";
import useApiCall from "../helpers/useApiCall";

const Register = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate()
    const { failed, loading, mutate: register } = useApiCall({
        url: "/api/register",
        method: "POST",
        body: {username: userName.trim(), password},
        onSuccess: () => navigate("/login", { state: { success: true } })
    })

    useEffect(() => {
        const handler = (k) => {
            if(k.code === 'Enter')
                document.getElementById('register').click()
        }
        window.addEventListener('keypress', handler);
        return () => window.removeEventListener('keypress', handler);
    }, [])

    return <Container maxWidth="sm">
        <Stack spacing={2}>
            {failed && <Alert severity="error">Nem sikerült regisztrálni. Felhasználói név már lehet, hogy foglat!</Alert>}
            <TextField autoFocus label="Név" value={userName} onChange={(e) => setUserName(e.target.value)}/>
            <TextField onKeyDown={(key) => key.code === 13 && register()} label="Jelszó" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            <Button id="register" variant="contained" disabled={loading} onClick={() => register()}>REGISZTRÁCIÓ</Button>
            <Button disabled={loading} onClick={() => navigate('/login')}>BEJELENTKEZÉS</Button>
        </Stack>
    </Container>
}

export default Register;
